/**
 * @module storage/local/list
 * 缓存管理: 表格
 */

import { compress, decompress } from '@/utils/compress'
import { isNumeric, isString } from '@/utils/validate'
import { getRouteName } from '@/utils/router'
import { get, set } from '../index'

/**
 * @param {string} [salt='']
 * @returns {string}
 * @description
 * - 当页面只有一个表格的时候(例如普通列表页面)，只需要将 route name 作为存储表格列宽信息的唯一标识
 * - 当一个页面含有多个表格的时候，需要分别存储表格列宽，目前前约定将 ref name of the <base-inline-edit-table> or <base-table> 作为表格列宽存储的唯一标识
 *
 * 注意：❗️ ref name 必须与 template 中的的保持一致，并且始终同步修改
 */
export function key(salt = '') {
  const routeName = getRouteName(this?.$route)

  if (isString(routeName) && routeName.trim()) {
    return `${routeName}TableColumnsWidths${salt ? ':' + salt : ''}`
  } else {
    console.error(
      'Invalid route name for generate "listHeader" cache key',
      routeName,
      this
    )
    return ''
  }
}

/**
 * @param {string} key
 * @param {Array} listHeader
 */
const init = (key, listHeader) => {
  if (!(isString(key) && key.trim())) {
    return void console.error(
      'Losed cache key for store listHeader infos (init)',
      key
    )
  }
  const cache = listHeader.reduce((prev, curr) => {
    prev[curr?.prop || curr?.type] = curr?.width || 'auto'
    return prev
  }, {})
  set('local', key, compress(cache))
}

/**
 * @param {string} key
 * @param {Array} listHeader
 * @return {Array}
 */
export const setup = (key, listHeader) => {
  try {
    if (isString(key) && key.trim()) {
      const cachedStr = get('local', key) || ''
      if (cachedStr) {
        const cached = decompress(cachedStr)
        return listHeader.map((v) => ({
          ...v,
          width: cached?.[v.prop || v?.type] || v?.width || 'auto'
        }))
      } else new Promise((resolve) => resolve(void init(key, listHeader)))
    } else console.error('Losed cache key for store listHeader infos', key)
  } catch (error) {
    console.error('listHeader setup error', error)
  }
  return listHeader
}

/**
 * @param {string} key
 * @param {object} column
 * @param {(number|string)} width
 */
export const updateColumnWidth = (key, column, width) => {
  if (!(isString(key) && key.trim())) {
    return void console.error(
      'Losed cache key for store listHeader infos (updateColumnWidth)'
    )
  }
  const cachedStr = get('local', key) || ''
  if (cachedStr) {
    const cached = decompress(cachedStr)
    const field = column.property || column?.type
    if (
      (isNumeric(width) || width === 'auto') &&
      isString(field) &&
      field.trim()
    ) {
      cached[column.property || column?.type] = width
    } else console.error('Invalid field or width', field, width)
    set('local', key, compress(cached))
  }
}
export default {
  key,
  setup,
  updateColumnWidth
}
